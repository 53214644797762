import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styling/home.scss';
import pdf from '../media/oasen.pdf';
import logo from './../media/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../components/Button';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../REST/axios';

import { GetToken, RemoveStorageData } from '../utilities/storage';
import { useEffect } from 'react';

export const Home = (props) => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = React.useState([]);

  const token = GetToken();

  useEffect(() => {
    if (token === null) {
      navigate('/');
      return;
    }
  });

  const handleClick = (e) => {
    e.preventDefault();

    RemoveStorageData();
    navigate('/');

    return;
  };

  const deleteOngoingTest = async (id) => {
    try {
      await axiosInstance.delete(`/submission/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const subs = submissions.filter((submission) => submission.id !== id);
      setSubmissions(subs);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log(axiosInstance.defaults);
    axiosInstance
      .get('/submission/get-all-of-user', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data;
        setSubmissions(data);
      })
      .catch((error) => {
        if (error && error.status === 401) {
          navigate('/');
        }
      });
  }, []);

  const incompleteSubmissions = submissions.filter(
    (submission) => submission.isCompleted === false
  );

  const completedSubmissions = submissions.filter(
    (submission) => submission.isCompleted === true
  );

  const downloadPDF = async (e, resultId) => {
    try {
      const response = await axiosInstance.get(`/public/pdf/${resultId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `resultat-${resultId}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };

  return (
    <div className='section home'>
      <div className='home__header'>
        <img className='img__logo' src={logo} />
        <button className='link-button' onClick={handleClick}>
          Logga ut
        </button>
      </div>
      <h2>Mina sidor</h2>
      <h1 className='heading'>Din Optimala Arbetssituation</h1>
      <p>
        Här är platsen där du hittar dina (eventuellt) tidigare OASer. De lagras
        hos oss i 12 månader och raderas därefter. Var noga med att själv spara
        dina resultat om du vill vara säker på att ha dem kvar. Här finner du
        även eventuellt pågående OASer som du ännu inte är klar med och kanske
        vill slutföra.
      </p>
      <button onClick={() => downloadPDF(124)}>Ladda ner</button>
      <div className='home__content'>
        <div className='home__former-tests'>
          <h2>Slutförda Oaser</h2>
          {completedSubmissions.length > 0 ? (
            completedSubmissions.map((submission, index) => {
              let date = new Date(submission.updatedAt);
              date = date.toISOString().split('T')[0];

              const pdf = `http://84.46.248.208/files/${submission.id}/${submission.id}-1.pdf`;

              return (
                <li
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => downloadPDF(e, submission.id)}
                >
                  {/* <a href={pdf} target='_blank'>
                    <FontAwesomeIcon icon={faDownload} />
                    {`OASen ${date}`}
                  </a> */}

                  <FontAwesomeIcon icon={faDownload} />
                  {`OASen ${date}`}
                </li>
              );
            })
          ) : (
            <p>Välkommen att göra din första OAS</p>
          )}
        </div>

        <div className='home__retake-test'>
          <div className='home__ongoing-test'>
            <h2>Pågående Oaser</h2>
            {incompleteSubmissions.length > 0 ? (
              incompleteSubmissions.map((submission) => {
                let date = new Date(submission.updatedAt);
                date = date.toISOString().split('T')[0];

                return (
                  <div key={submission.id}>
                    <small>Du har en pågående OAS från {date}</small>
                    <div className='home__ongoing-wrapper'>
                      <Link to={`/kan/${submission.id}`}>
                        <Button
                          className='button--secondary home__ongoing-button'
                          text='Slutför sparad OAS'
                        />
                      </Link>

                      <button
                        className='home__ongoing-delete'
                        onClick={(e) => deleteOngoingTest(submission.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Inga pågående OASer</p>
            )}
          </div>
          <Link to='/instruktioner' className='button button--primary'>
            Fortsätt till ny OAS
          </Link>
        </div>
      </div>
    </div>
  );
};
