import React from "react";
import "../styling/form-wrapper.scss";

export const TextInput = (props) => {
  return (
    <div className="input-wrapper">
      <label htmlFor={props.for}>{props.label}</label>
      <input
        onChange={props.onChange}
        name={props.name}
        type={props.type}
        id={props.for}
        required={props.required}
        value={props.value}
      ></input>
    </div>
  );
};
