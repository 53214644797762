import React from "react";
import "../styling/answer.scss";

export const Answer = (props) => {
  return (
    <div className="answer-wrapper">
      <label htmlFor={props.id}>{props.question}</label>
      <p id={props.id}>{props.answer}</p>
    </div>
  );
};
