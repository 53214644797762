import React from 'react';
import { useState } from 'react';
import '../styling/circles.scss';
import {
  translateVillScoreToPosition,
  translateKanScoreToPosition,
  translateSammanhangScoreToPosition,
} from '../utilities/CirclePositions';

export const Circles = (props) => {
  const [showScoreKan, setShowScoreKan] = useState(false);
  const [showScoreVill, setShowScoreVill] = useState(false);
  const [showScoreSammanhang, setShowScoreSammanhang] = useState(false);

  const villPosition = translateVillScoreToPosition(props.scoreVill);
  const kanPosition = translateKanScoreToPosition(props.scoreKan);
  let sammanhangPosition = translateSammanhangScoreToPosition(
    props.scoreSammanhang
  );

  // To prevent the Sammanhangs ring not to overflow on the top
  if (
    props.scoreKan <= 2 &&
    props.scoreVill <= 2 &&
    props.scoreSammanhang == 6
  ) {
    sammanhangPosition = 10;
  }

  const willStyle = { left: `${villPosition}%` };
  const canStyle = { left: `${kanPosition}%` };
  const sammanhangStyle = { top: `${sammanhangPosition}%` };

  setTimeout(() => {
    setShowScoreKan(true);
  }, 1000);

  setTimeout(() => {
    setShowScoreVill(true);
  }, 2000);

  setTimeout(() => {
    setShowScoreSammanhang(true);
  }, 2500);

  return (
    <>
      <div className='scores__container'>
        <span className='tag tag--will'>Vill: {props.scoreVill}</span>
        <span className='tag tag--sammanhang'>
          Sammanhang: {props.scoreSammanhang}
        </span>
        <span className='tag tag--can'>Kan: {props.scoreKan}</span>
      </div>
      <div className='result__circles'>
        <div className='circle-wrapper'>
          <div
            className='circle will'
            style={showScoreVill ? willStyle : {}}
          ></div>
          <div
            className='circle can'
            style={showScoreKan ? canStyle : {}}
          ></div>
          <div
            className='circle sammanhang'
            style={showScoreSammanhang ? sammanhangStyle : {}}
          >
            <span className='tag tag--oas'>OAS</span>
            <span className='tag tag--strengths'>Styrkor</span>
          </div>
        </div>
      </div>
    </>
  );
};
