import React from "react";
import "../styling/textarea.scss";

export const Textarea = (props) => {
  return (
    <div className="textarea-wrapper">
      <label htmlFor={props.id}>{props.label}</label>
      <textarea
        key={props.key}
        id={props.id}
        required={props.required}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      ></textarea>
    </div>
  );
};
