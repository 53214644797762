import React, { useEffect } from "react";
import { BrowserRouter, Routes, Link, Route } from "react-router-dom";

import "./App.scss";

import kan from "./media/kan.png";
import vill from "./media/vill.png";
import sammanhang from "./media/sammanhang.png";
import styrkor from "./media/styrkor.png";

import { Result } from "./Routes/Result";
import { Login } from "./Routes/Login";
import { Questionnaire } from "./Routes/Questionnaire";
import { Instructions } from "./Routes/Instructions";
import { Home } from "./Routes/Home";
import { ResetPassword } from "./Routes/ResetPassword";
import { ChangePassword } from "./Routes/ChangePassword";
import { Gdpr } from "./Routes/Gdpr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  const [localState, setLocalState] = React.useState({
    dataFetched: false,
    sections: [],
  });

  useEffect(() => {
    // axiosInstance.get("/section/get-all").then((response) => {
    //   const data = response.data;
    //   setLocalState({ ...localState, sections: data, dataFetched: true });
    //   //TODOS: Use data from database instead of using hardcoded questions
    // });
  }, []);

  const canQuestionnaire = {
    id: 1,
  };

  const willQuestionnaire = {
    id: 2,
  };

  const sammanhangQuestionnaire = {
    id: 3,
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-password/:token" element={<ChangePassword />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/instruktioner" element={<Instructions />} />
          <Route
            path="/kan/:id"
            element={
              <Questionnaire
                section="can"
                title="Kan"
                key={1}
                preamble={[
                  "I ",
                  <span className="kan">KAN</span>,
                  "-ringen ligger dina kunskaper, erfarenheter, gåvor, talanger, egenskaper och din personlighet. Börja med att utforska ditt ",
                  <span className="kan">KAN</span>,
                  ". Mycket av det du behöver här hittar du i din CV.",
                  <br />,
                  "Lyssna på Barbara i den korta videon nedan så får du lite mer information innan du påbörjar dina egna reflektioner.",
                ]}
                sectionImg={kan}
                videoURL={`https://youtu.be/RfT_V7TQKeQ`}
                instructions={[
                  "Nedan har du tio olika frågor som kan hjälpa dig att bättre förstå dina egna ",
                  <span className="kan">KAN</span>,
                  "-styrkor och därmed hur stor din ",
                  <span className="kan">KAN</span>,
                  "-ring är.",
                  <br />,
                  "Läs frågorna, reflektera och skriv anteckningar om det hjälper dig Det är inget krav att skriva i fälten. Det är bara ett stöd för att du lättare skall kunna reflektera.",
                  <br />,
                  "Efter att hela OASen är slutförd har du möjlighet att få en PDF med alla dina anteckningar samlade.",
                  <br />,
                  "Om du behöver avbryta övningen så kom ihåg att trycka på spara först. Då finns allt kvar när du loggar in på nytt.",
                  <br />,
                ]}
                data={canQuestionnaire}
                textForScale={[
                  "Genom frågorna ovan och dina svar har du nu fått möjlighet att reflektera över dina ",
                  <span className="kan">KAN</span>,
                  "-styrkor.",
                  <strong>
                    Om du skulle sammanfatta hur stark din{" "}
                    <span className="kan">KAN</span>-ring är på en skala mellan
                    1 och 6, var skulle den då hamna?
                  </strong>,
                  "Kanske kan det vara lite svårt att uppskatta en med siffra innan du har gjort de andra frågorna på övningarna; ",
                  <span className="vill">VILL</span>,
                  " och ",
                  <span className="sammanhang">SAMMANHANG</span>,
                  " men sätt ändå det som känns mest rätt just nu. Du har möjlighet att gå tillbaka och justera sedan, innan du trycker fram ditt slutresultat. ",
                  <strong>
                    Observera att dina anteckningar inte har någon koppling till
                    ditt slutresultat, utan enbart skalsvaret.
                  </strong>,
                ]}
                nextRoute="VILL"
                prevRoute="instruktioner"
              />
            }
          />
          <Route
            path="/vill/:id"
            element={
              <Questionnaire
                section="will"
                title="Vill"
                key={2}
                preamble={[
                  "I ",
                  <span className="vill">VILL</span>,
                  "-ringen ligger drivkrafter, intressen, motivation och behov, det du brinner för, det du känner intresse och engagemang för, där din passion ligger. Detta upplevs ibland svårare att sätta fingret på.",
                  <br />,
                  "Lyssna på Barbara i den korta videon nedan så får du lite mer information innan du påbörjar dina egna reflektioner.",
                ]}
                sectionImg={vill}
                videoURL="https://youtu.be/pASeTAA835Y"
                instructions={[
                  "Nu följer tio olika frågor som kan hjälpa dig att bättre förstå ditt eget ",
                  <span className="vill">VILL</span>,
                  "-styrkor och därmed hur stor din ",
                  <span className="vill">VILL</span>,
                  "-ring är. Läs frågorna, fundera och skriv anteckningar om du vill. Som du redan vet är det inget krav att skriva i fälten. Det är bara ett stöd för att du lättare skall kunna reflektera och komma ihåg. Efter att hela OASen är slutförd har du möjlighet att få en PDF med alla dina anteckningar samlade. Om du behöver avbryta övningen så kom ihåg att trycka på spara först. Då finns allt kvar när du loggar in på nytt.",
                ]}
                data={willQuestionnaire}
                textForScale={[
                  "Genom frågorna ovan och dina svar har du nu fått möjlighet att reflektera över ditt ",
                  <span className="vill">VILL</span>,
                  ".",
                  <strong>
                    Om du skulle sammanfatta hur stark din{" "}
                    <span className="vill">VILL</span>-ring är på en skala
                    mellan 1 och 6, var skulle den då hamna?
                  </strong>,
                  "Kanske är det fortfarande svårt att uppskatta en siffra innan du ser helheten, men som tidigare nämnt, sätt ändå en siffra så kan du eventuellt justera på slutet innan du trycker ut slutresultatet.",
                  <strong>
                    Observera att dina anteckningar inte har någon koppling till
                    ditt slutresultat, utan enbart skalsvaret.
                  </strong>,
                ]}
                extraImg={styrkor}
                extraText={[
                  "Innan vi går vidare till den sista cirkeln så kommer här lite förklaring på området där ",
                  <span className="kan">KAN </span>,
                  "och ",
                  <span className="vill">VILL </span>,
                  "överlappar varandra. Här har du dina STYRKOR och de är viktiga.",
                  <br />,
                  "För det är faktiskt ingen styrka att bara kunna, men inte vilja. Det är bara en förmåga. Du har många olika arbetsuppgifter som ligger inom ramen för ditt uppdrag men som kanske endast ligger inom ramen för din förmåga, som du kanske inte direkt brinner för eller tycker är så roligt alltid. Så är det på alla arbetsplatser och i alla roller. Så är det säkert för dig också.",
                  <br />,
                  "Det gäller att finna en balans där majoriteten av dina uppgifter ligger inom ramen för dina styrkor. Resten behöver du hitta ett förhållningssätt till. Om du kan och det är möjligt där du befinner dig.",
                  <br />,
                  "Tänk 80/20-regeln. 80% av dina arbetsuppgifter bör ligga inom ramen för det du ",
                  <span className="kan">KAN </span>,
                  "och ",
                  <span className="vill">VILL </span>,
                  "dvs brinner och känner engagemang för. 20% är arbetsuppgifter som ligger inom ramen för ditt ansvarsområde och bara skall göras. Så länge balansen är 80/20 är det lätt att hantera. Men om förhållandet skulle bli det omvända, dvs 20/80… ja, då kommer det att börja skava i skon. Då behöver du ta tag i skavet.",
                  <br />,
                  "Kanske väcker dessa rader nya tankar hos dig som du vill göra en anteckning kring. Använd i så fall området ovan som du redan har antecknat i och fyll på med dina nya reflektioner.",
                ]}
                nextRoute="SAMMANHANG"
                prevRoute="kan"
              />
            }
          />
          <Route
            path="/sammanhang/:id"
            element={
              <Questionnaire
                section="sammanhang"
                title="Sammanhang"
                key={3}
                preamble={[
                  "I ",
                  <span className="sammanhang">SAMMANHANG</span>,
                  "-ringen ligger den organisation där du finns och verkar – visionen, missionen, kulturen, värdegrunden, ledarskapet, kollegorna, teamet, arbetsmiljön – både den psykosociala och den fysiska, arbetsvillkor, rollen och uppdraget.",
                  <br />,
                  "Här handlar det om att när jag ",
                  <span className="kan">KAN</span>,
                  " och är bra på något och dessutom ",
                  <span className="vill">VILL</span>,
                  " göra det som jag är bra på, så är det just här, i detta ",
                  <span className="sammanhang">SAMMANHANG</span>,
                  ", i denna organisation och ingen annanstans som jag vill vara.",
                  <br />,
                  "Lyssna på Barbara i den korta videon nedan så får du lite mer information innan du påbörjar dina egna reflektioner.",
                ]}
                sectionImg={sammanhang}
                videoURL="https://youtu.be/N-a9OVrHcrw"
                instructions={[
                  "Nu följer tio olika frågor som kan hjälpa dig att bättre förstå din personliga relation till din nuvarande arbetsplats. Skulle du just nu vara ”mellan jobb” så relatera till din senaste arbetsplats.",
                  <br />,
                  "Tänk både på vad du gillar – och vad du ogillar. Liksom tidigare, när det gäller ",
                  <span className="kan">KAN</span>,
                  " respektive ",
                  <span className="vill">VILL</span>,
                  "-ringen så är det viktigt att ta reda på vad det är som gör att du känner att ",
                  <span className="sammanhang">SAMMANHANG </span>,
                  " är helt rätt – respektive fel och läcker energi.",
                  <br />,
                  "Läs frågorna, fundera och skriv anteckningar om du vill. Efter att hela OASen är slutförd har du möjlighet att få en PDF med alla dina anteckningar samlade. Om du behöver avbryta övningen så kom ihåg att trycka på spara först. Då finns allt kvar när du loggar in på nytt.",
                ]}
                data={sammanhangQuestionnaire}
                textForScale={[
                  "Genom frågorna ovan och dina svar har du nu fått möjlighet att reflektera över ditt ",
                  <span className="sammanhang">SAMMANHANG</span>,
                  ".",
                  <strong>
                    Om du skulle sammanfatta hur stark din
                    <span className="sammanhang"> SAMMANHANG</span>-ring är på
                    en skala mellan 1 och 6, var skulle den då hamna?
                  </strong>,
                  "Kom ihåg att du kan gå tillbaka och justera de två andra siffrorna om du känner att det behövs nu när du ser helheten. Men gör först färdigt uppgiften nedan. Glöm inte trycka på den helgröna knappen ”Spara” innan du eventuellt går tillbaka.",
                  <strong>
                    Observera att dina anteckningar inte har någon koppling till
                    ditt slutresultat, utan enbart skalsvaret.
                  </strong>,
                ]}
                nextRoute="resultat"
                prevRoute="vill"
              />
            }
          />
          <Route path="/resultat/:id" element={<Result />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
