export const GetUser = () => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user);
  }

  return null;
};

export const SetUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const GetToken = () => {
  const token = localStorage.getItem("token");

  return token;
};

export const SetToken = (token) => {
  localStorage.setItem("token", token);
};

export const RemoveStorageData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};
