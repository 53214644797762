import React from "react";
import "../styling/buttons.scss";

export const Button = (props) => {
  return (
    <div className="button-wrapper">
      <button className={`button ${props.className}`}>{props.text}</button>
    </div>
  );
};
