import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styling/login.scss";
import logo from "../media/logo.png";

import axiosInstance from "../REST/axios";

import { TextInput } from "../components/TextInput";
import { Submit } from "../components/Submit";
import { SetToken, SetUser } from "../utilities/storage";

export const Login = () => {
  const navigate = useNavigate();
  const [registrationState, setRegistrationState] = React.useState({
    name: "",
    organization: "",
    email: "",
    password: "",
    confirmPassword: "",
    loading: false,
    errorMessage: "",
  });

  const [loginState, setLoginState] = React.useState({
    email: "",
    password: "",
    loading: false,
  });
  const [loginErrorMessage, setLoginErrorMessage] = React.useState("");

  const handleSubmitRegistration = (e) => {
    setRegistrationState({ ...registrationState, loading: true });
    e.preventDefault();

    const data = {
      name: registrationState.name,
      organization: registrationState.organization,
      email: registrationState.email,
      password: registrationState.password,
    };

    axiosInstance.post("/auth/signup", data).then((response) => {
      const data = response.data;

      if (data.created == true) {
        SetToken(data.token);
        SetUser(data.user);
        navigate("/home");
        return;
      }

      if (data.created == false) {
        setRegistrationState({
          ...registrationState,
          errorMessage: "User already exists. ",
        });
      }
    });
  };

  const onChangeRegistrationFormInput = (event) => {
    setRegistrationState({
      ...registrationState,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeLoginFormInput = (event) => {
    setLoginState({
      ...loginState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitLogin = async (e) => {
    setLoginState({ ...loginState, loading: true });
    e.preventDefault();

    const data = {
      email: loginState.email,
      password: loginState.password,
    };

    try {
      const response = await axiosInstance.post("/auth/signin", data);
      const responseData = response.data;

      SetToken(responseData.token);
      SetUser(responseData.user);

      if (responseData.user !== undefined) {
        navigate("/home");
        return;
      }
    } catch (e) {
      setLoginErrorMessage("Invalid credentials.");
    } finally {
      setLoginState({ ...loginState, loading: false });
    }
  };

  return (
    <div className="section login">
      <img className="img__logo" src={logo} />
      <h2>
        Välkommen till <span class="lowecase">i</span>Turn och OASen
      </h2>

      <p>
        Välkommen till iTURN och ett av våra verktyg som vi kallar OASen. Vi
        hoppas att du genom denna reflektionsövning skall få insikter som
        hjälper dig att navigera i din nuvarande arbetssituation - och även att
        förstå dig själv bättre.
      </p>
      <p>
        Vi rekommenderar att du avsätter ungefär 45-60 minuter till övningen så
        att du hinner reflektera och anteckna i lugn och ro.
      </p>
      <p>
        Innan du kan börja behöver du registrera dig så att vi sedan kan maila
        dig slutresultatet.
      </p>
      <p>
        Är det första gången du gör OASen så använd registreringsfälten och
        uppge ett eget lösenord.
      </p>
      <p>
        Har du tidigare påbörjat eller slutfört en OAS det senaste året så
        använd samma inloggningsuppgifter som förra gången.
      </p>

      <div className="landing-page">
        <form
          className="landing-page__sign-up"
          onSubmit={handleSubmitRegistration}
        >
          <h2>Registrering</h2>
          <div className="form-wrapper">
            <TextInput
              type="text"
              for="name"
              label="Namn"
              required="required"
              name="name"
              onChange={onChangeRegistrationFormInput}
            />
            <TextInput
              type="text"
              for="companyname"
              label="Organisation"
              name="organization"
              onChange={onChangeRegistrationFormInput}
            />
          </div>
          <div className="form-wrapper">
            <TextInput
              type="email"
              for="email"
              label="E-mail"
              required="required"
              name="email"
              onChange={onChangeRegistrationFormInput}
            />
          </div>
          <div className="form-wrapper">
            <TextInput
              type="password"
              for="password"
              label="Lösenord"
              required="required"
              name="password"
              onChange={onChangeRegistrationFormInput}
            />
            <TextInput
              type="password"
              for="password"
              label="Lösenord"
              required="required"
              name="confirmPassword"
              onChange={onChangeRegistrationFormInput}
            />
          </div>
          <div className="form-wrapper">
            <input type="checkbox" required />
            <label>
              Samtycker du om vår datalagring enligt
              <Link to="/gdpr" className="gdpr">
                GDPR?
              </Link>
            </label>
          </div>
          <p style={{ color: "red" }}>{registrationState.errorMessage}</p>
          <Submit value="Registrera" loading={registrationState.loading} />
        </form>

        <form className="landing-page__login" onSubmit={handleSubmitLogin}>
          <h2>Login</h2>
          <div className="form-wrapper">
            <TextInput
              type="email"
              for="email"
              label="E-mail"
              required="required"
              name="email"
              onChange={onChangeLoginFormInput}
            />
            <TextInput
              type="password"
              for="password"
              label="Lösenord"
              required="required"
              name="password"
              onChange={onChangeLoginFormInput}
            />
          </div>
          <p style={{ color: "red" }}>{loginErrorMessage}</p>
          <Submit value="Logga in" loading={loginState.loading} />

          <Link to="/reset-password" className="forgot-password">
            Glömt lösenordet?
          </Link>

          <br />
          <br />
          <br />
          <img
            src="/eu.png"
            alt=""
            style={{ width: "35px", height: "20px", verticalAlign: "top" }}
          />
          <span
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              marginLeft: "0.5rem",
              width: "92%",
              display: "inline-block",
            }}
          >
            Detta projekt delfinansieras genom Digitaliseringschecken av Region
            Skåne och Europeiska regionala utvecklingsfonden.
          </span>
        </form>
      </div>
    </div>
  );
};
