import React from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../styling/result.scss";
import logo from "../media/logo.png";

import { Circles } from "../components/Circles";
import { Answer } from "../components/Answer";
import { GetToken, GetUser } from "../utilities/storage";
import axiosInstance from "../REST/axios";

export const Result = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = GetToken();
  const [result, setResult] = React.useState({
    loaded: false,
    data: {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (token === null) {
      navigate("/");
      return;
    }
  });

  const handleOnClick = (e) => {
    axiosInstance.post(
      "/submission/email-submission",
      {
        submissionId: id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    e.target.innerHTML = "Ditt resultat är skickat!";
    e.target.disabled = true;
  };

  const user = GetUser();

  const GetResult = async () => {
    try {
      const response = await axiosInstance.get(`/submission/get-result/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setResult({ ...result, loaded: true, data: response.data });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    GetResult();
  }, []);

  const submissionSections =
    result.loaded === true && result.data.submissionSections;

  const can =
    submissionSections &&
    result.data.submissionSections.find(
      (section) => section.section.name.toLowerCase() === "kan"
    );
  const will =
    submissionSections &&
    result.data.submissionSections.find(
      (section) => section.section.name.toLowerCase() === "vill"
    );
  const sammanhang =
    submissionSections &&
    result.data.submissionSections.find(
      (section) => section.section.name.toLowerCase() === "sammanhang"
    );

  return (
    <div className={`section result`}>
      <div className="user-info">
        <img className="img__logo" src={logo} />
        <p>
          {user.name} {user.date}
        </p>
      </div>
      <h1>Resultat – Din OAS</h1>

      {result.loaded === true && (
        <Circles
          scoreVill={will.score}
          scoreKan={can.score}
          scoreSammanhang={sammanhang.score}
        />
      )}
      <div className="results__content">
        <p>
          <h2>Här är din OAS</h2> På bilden ovan framträder nu din egen OAS, som
          baseras på de värden mellan 1 och 6 som du tidigare uppgett. Ser du en
          stor överlappning mellan de tre ringarna så är det ett gott tecken.
          Det överlappande området tyder på att du har ett starkt engagemang och
          trivs på din arbetsplats. Är någon av ringarna långt ifrån de andra
          två så kanske du skall fundera över vad det står för? Skulle alla
          ringarna vara långt ifrån varandra så är ditt engagemang sannolikt
          lågt och du upplever förmodligen ett energiläckage. Din OAS är helt
          enkelt för liten. Vad tänker och känner du när du ser ditt resultat?
        </p>
        <p>
          <h2>OBALANSER</h2> Generellt kan man säga att vid obalans i OASen, om
          det läcker energi från någon av ringarna eller t o m från alla
          ringarna samtidigt, uppstår ett skav i skon. Om du går med skavet för
          länge, då blir det snart ett sår som med tiden blir inflammerat och
          kanske t o m börjar blöda. Nu har OASen istället blivit ett KAOS. Då
          är det viktigt att snabbt försöka identifiera vad som behöver göras
          för att åter uppnå balans och stabilitet, för att läka såret.
        </p>
        <p>
          <h2>ENGAGEMANG</h2> Om du har en stabil OAS med stor överlappning
          känner du högt engagemang i ditt arbete och presterar bra. Du har ett
          eget ansvar att identifiera och underhålla din OAS och berätta för din
          chef och omgivning vad du behöver för att hålla din OAS i balans. Då
          får din chef möjlighet att skapa förutsättningar för att du skall
          kunna hitta din motivation och ditt engagemang i vardagen. Men det är
          ingen annan än du själv som vet hur din OAS ser ut. Det är bara du som
          vet om du har ett skav i skon eller om du går till jobbet med lätta
          steg och ett leende på läpparna. Att ta ansvar för sin OAS, det är att
          ta ansvar för sitt självledarskap.
        </p>
        <br />
        <p>
          Strax skall du avsluta den här övningen och kommer att få din OAS i en
          PDF via mail. Men låt inte din process ta slut här. Låt ditt resultat
          sjunka in. Ta dig sedan tid att reflektera och känna efter igen. Här
          kommer några sammanfattande frågor som du då kan ställa dig för att
          bli ännu mer klar över din OAS och din väg framåt.
        </p>
        <p>
          <ul>
            <li>
              Vilken eller vilka ringar är starka och vad innebär det för dig?
            </li>
            <li>
              Vilken eller vilka läcker energi och vad innebär det för dig?
            </li>
            <li>Vad skall till för att återskapa balansen (om det behövs)?</li>
            <li>Vad kan du göra själv?</li>
            <li>
              Vilket stöd behöver du - från kollegor, chef, vänner, din coach?
            </li>
            <li>Vem behöver du prata med om du har skav?</li>
            <li> När ska du i så fall göra det?</li>
          </ul>
        </p>
        <p>
          Om det nu är så väl att du inte har något som skaver, gläds åt det och
          påminn dig själv om vad det är som gör att du faktiskt går till jobbet
          med lätta steg och ett leende på läpparna”. Det är det
          självledarskapet handlar om.
          <hr />
          Snyggt jobbat!
        </p>

        {result.loaded === true &&
          result.data.submissionSections.map((submissionSection, index) => {
            const section = submissionSection.section;
            const answers = submissionSection.submissionSectionAnswers;

            return (
              <div>
                <h2 className={section.name.toLowerCase()}>
                  Dina anteckningar från {section.name}-frågorna
                </h2>
                <div>
                  {answers.map((answer) => {
                    return (
                      <Answer
                        question={answer.question.question}
                        answer={answer.answer}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      <p className="small">
        Nu är det dags att få ditt resultat på mail. Om du inte får något mail -
        kom ihåg att titta i din skräppost! Alla dina anteckningar som du ser
        nedan kommer också att följa med i samma mail.
      </p>
      <div className="buttons-wrapper sticky">
        <Link to={`/home`} className="button button--outline">
          Gå till Mina sidor
        </Link>
        <button
          mailto=""
          className="button button--primary button--send-results"
          onClick={handleOnClick}
        >
          Maila mig mitt resultat
        </button>
      </div>
    </div>
  );
};
