import React from "react";
import { Link } from "react-router-dom";
import "../styling/home.scss";
import logo from "../media/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const Gdpr = (props) => {
  return (
    <div className="section home">
      <img className="img__logo" src={logo} />
      <Link to="/" className="go-back">
        <FontAwesomeIcon icon={faChevronLeft} />
        Gå tillbaka till inloggning
      </Link>
      <h2>Mina sidor</h2>
      <h1 className="heading">GDPR</h1>
      <div className="gdpr__content">
        <p>
          iTURN följer gällande lagar och regler för datalagring av
          personuppgifter.{" "}
        </p>
        <p>
          Dina registreringsuppgifter används endast för att kunna skicka dig
          ditt slutresultat efter övningens slut, samt för att du skall kunna
          logga in på nytt om du inte hunnit slutföra övningen.
        </p>
        <p>
          Dina inloggningsuppgifter lagras under 12 månader och raderas därefter
          helt.
        </p>
        <p>
          Även dina resultatsvar och anteckningar raderas. Skall du göra
          övningen på nytt efter 12 månader behöver du registrera ett nytt
          konto.
        </p>
      </div>
    </div>
  );
};
