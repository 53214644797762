import React from 'react';
import { Button } from '../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import '../styling/instructions.scss';
import logo from '../media/logo.png';
import eu from '../media/eu.png';
import oas from '../media/oas-instructions.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../REST/axios';
import { GetToken, GetUser, RemoveStorageData } from '../utilities/storage';

export const Instructions = (props) => {
  const navigate = useNavigate();

  const token = GetToken();
  if (token === null) {
    navigate('/');
    return;
  }

  const handleClick = (e) => {
    e.preventDefault();

    RemoveStorageData();
    navigate('/');

    return;
  };

  const addSubmission = async () => {
    const user = GetUser();

    try {
      const response = await axiosInstance.post(
        '/submission',
        {
          user: user.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        const submission = response.data[0];
        navigate('/kan/' + submission.id);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`section instructions`}>
      <div className='header'>
        <div>
          <img className='img__logo' src={logo} />
          <Link to={`/home`} className='go-back'>
            <FontAwesomeIcon icon={faChevronLeft} />
            Gå tillbaka till Mina Sidor
          </Link>
        </div>
        <button className='link-button' onClick={handleClick}>
          Logga ut
        </button>
      </div>

      <h2>Instruktioner</h2>
      <h1 className='heading'>Din Optimala ArbetsSituation</h1>
      <p>
        I denna övning skall vi nu dyka ner i den tårtbit i Balanshjulet som
        handlar om ditt arbete – Yrke / Karriär.
      </p>
      <img src={oas} className='instructions__oas' />
      <p>
        Att vara i sin OAS innebär att vara någonstans där man får energi och
        ger energi, där man är i sitt esse, trivs och mår bra. Det är här man
        vill vara. Det är hit man vill gå. Det är här man är i flow.
      </p>
      <p>
        Att vara i sin OAS i yrkessammanhang innebär samma sak – att vara och
        vistas någonstans, i en miljö där man är sitt bästa jag, där man kan det
        man ska kunna för att lyckas, där man brinner för det man gör och är bra
        på och där sammanhanget känns helt rätt.
      </p>
      <p>
        Titta på modellen ovan. Det är tre parametrar som skall till för att du
        skall vara i din OAS. Det är <span className='kan'>KAN</span>,{' '}
        <span className='vill'>VILL</span>, och{' '}
        <span className='sammanhang'>SAMMANHANG</span> och när de tre är i
        balans, då är du i din Optimala ArbetsSituation. Då har du en stor
        överlappning mellan de tre ringarna.
      </p>
      <p>
        OASen kan självklart se olika ut för olika individer och från gång till
        annan beroende på var du befinner dig i livet och hur nöjd du är med
        tillvaron.
      </p>
      <p>
        I övningen som nu följer kommer du att få möjlighet att reflektera över
        din arbetssituation och i slutänden få ett resultat som visar hur mycket
        dina OAS-ringar överlappar. Är du redo så kör vi igång!
      </p>
      <Link to='/kan'></Link>

      <button onClick={addSubmission} className='button button--primary'>
        Låt oss skapa din OAS!
      </button>

      <div className='eu-project'>
        <img src={eu} />
        <p className='small'>
          Detta projektet delfinansieras av Europeiska regionala
          utvecklingsfonden
        </p>
      </div>
    </div>
  );
};
