export const translateVillScoreToPosition = (score) => {
  switch (score) {
    case 1:
      return -30;
    case 2:
      return -25;
    case 3:
      return -20;
    case 4:
      return -15;
    case 5:
      return -10;
    case 6:
      return -5;
  }
  return 0;
};

export const translateKanScoreToPosition = (score) => {
  switch (score) {
    case 1:
      return 30;
    case 2:
      return 25;
    case 3:
      return 20;
    case 4:
      return 15;
    case 5:
      return 10;
    case 6:
      return 5;
  }
  return 0;
};

export const translateSammanhangScoreToPosition = (score) => {
  switch (score) {
    case 1:
      return 50;
    case 2:
      return 40;
    case 3:
      return 30;
    case 4:
      return 20;
    case 5:
      return 10;
    case 6:
      return 5;
  }
  return 0;
};
