import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Textarea } from "../components/Textarea";
import { Submit } from "../components/Submit";
import logo from "../media/logo.png";
import kan from "../media/kan.png";
import "../styling/questionnaire.scss";
import "../styling/scale.scss";
import "../styling/buttons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../REST/axios";
import { GetToken } from "../utilities/storage";
import { useForm, useFieldArray } from "react-hook-form";

export const Questionnaire = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = GetToken();
  const nextRoute = `/${props.nextRoute}/${id}`;

  useEffect(() => {
    if (token === null) {
      navigate("/");
      return;
    }
  });

  const [localState, setLocalState] = useState({
    questions: [],
    saveButtonSpinner: false,
    saveAndContinueButtonSpinner: false,
  });

  const data = props.data;

  const { control, handleSubmit, register, setValue, formState, setFocus } =
    useForm({
      defaultValues: {
        remarks: "",
        scale: "1",
      },
    });

  const { fields, append, remove, replace, reset } = useFieldArray({
    name: "answers",
    control,
  });

  async function onSubmit(res) {
    console.log(res);
    const data = {
      submission: id,
      section: props.data.id.toString(),
      score: res.scale,
      answers: [],
      remarks: res.remarks,
    };

    for (const answer of res.answers) {
      const item = {
        questionId: answer.id,
        answer: answer.answer,
      };

      data.answers.push(item);
    }

    try {
      await axiosInstance.post("/submission/section", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLocalState({
        ...localState,
        saveButtonSpinner: false,
        saveAndContinueButtonSpinner: false,
      });

      if (localState.saveAndContinueButtonSpinner === true) {
        navigate(nextRoute);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setLocalState({
      ...localState,
      saveAndContinueButtonSpinner: false,
      saveButtonSpinner: false,
    });
  }, [props.data.id]);

  const LoadQuestions = async () => {
    window.scrollTo(0, 0);
    try {
      let response = await axiosInstance.get(
        `/question/get-for-section?sectionId=${data.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const questions = response.data;

      response = await axiosInstance.get(
        `/submission/get-section?submissionId=${id}&sectionId=${data.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const userAnswersFromDatabase = response.data.answers;
      console.log("userAnswersFromDatabase", response.data);

      remove();

      for (const question of questions) {
        const questionId = question.id;

        const answer = userAnswersFromDatabase.find(
          (a) => a.question.id === questionId
        );

        if (answer) {
          question.answer = answer.answer;
        } else {
          question.answer = "";
        }

        append(
          { answer: question.answer, question: question.question },
          { shouldFocus: false }
        );
      }

      setValue("remarks", response.data.remarks);
      setValue("scale", response.data.score.toString());

      // setFocus();

      replace(questions);

      console.log(questions);
      setLocalState({ ...localState, questions: questions });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    LoadQuestions();
  }, [props.data.id]);

  let prevRoute = `/${props.prevRoute}/${id}`;
  if (props.prevRoute == "instruktioner") {
    prevRoute = `/${props.prevRoute}`;
  }

  const onClickSave = () => {
    setLocalState({ ...localState, saveButtonSpinner: true });
  };

  const onClickSaveAndContinue = () => {
    setLocalState({ ...localState, saveAndContinueButtonSpinner: true });
  };

  const getVideoUrl = (section) => {
    console.log(section);
    if (section == "can") {
      return "https://www.youtube.com/embed/1Rp00FO-AL8?si=AzTk08KK4ehZMw__";
    }

    if (section == "will") {
      return "https://www.youtube.com/embed/Z9CcP0B1htM?si=J5CjbaVoPrEUSCES";
    }

    if (section == "sammanhang") {
      return "https://www.youtube.com/embed/5SX_iowIioc?si=EUKu97SJC_Wupllw";
    }

    return "";
  };

  return (
    <div className="questionnaire section">
      <img className="img__logo" src={logo} />
      <Link to={prevRoute} className="go-back">
        <FontAwesomeIcon icon={faChevronLeft} />
        Gå tillbaka till {props.prevRoute}
      </Link>

      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inner-wrapper">
          <h2>OASen</h2>
          <h1>{props.title}</h1>
          <div class="questionnaire__preamble-wrapper">
            <p>{props.preamble}</p>
            <img src={props.sectionImg} />
          </div>

          <iframe
            width="560"
            height="315"
            src={getVideoUrl(props.section)}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <p>{props.instructions}</p>
          {fields.map((item, i) => (
            <div key={i}>
              <div>
                <div className="textarea-wrapper">
                  <label htmlFor={item.id}>{item.question}</label>
                  <textarea
                    key={item.id}
                    id={item.id}
                    name={`answers[${i}]answer`}
                    {...register(`answers.${i}.answer`)}
                  ></textarea>
                </div>
              </div>
            </div>
          ))}
          <div className="scale-wrapper">
            <label>{props.textForScale}</label>
            <div className="scale-label">
              <span>Mycket svag</span>
              <span>Mycket stark</span>
            </div>
            <div className="form-wrapper">
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    key={"scale1"}
                    type="radio"
                    className="radio__button"
                    value="1"
                    name="scale"
                    defaultChecked
                    {...register("scale")}
                    required
                  />
                  1
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    key={"scale2"}
                    type="radio"
                    className="radio__button"
                    value="2"
                    name="scale"
                    {...register("scale")}
                  />
                  2
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    key={"scale3"}
                    type="radio"
                    className="radio__button"
                    value="3"
                    name="scale"
                    {...register("scale")}
                  />
                  3
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    className="radio__button"
                    value="4"
                    name="scale"
                    {...register("scale")}
                  />
                  4
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    className="radio__button"
                    value="5"
                    name="scale"
                    {...register("scale")}
                  />
                  5
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    className="radio__button"
                    value="6"
                    name="scale"
                    {...register("scale")}
                  />
                  6
                </label>
              </div>
            </div>
          </div>
          <div className="textarea-wrapper">
            <label htmlFor={"remarks"}>
              Om ditt svar är 4 eller mer, fundera över vad det är som gör att
              den är stark. Om ditt svar är 3 eller mindre, fundera över vad det
              är som gör att den är svag.
              <br />
              Problematisera inte bara kring ett läckage. Ta också ansvar för
              att sätta fingret på vad det är som gör att ringen är stark. Så
              att du vet vad du vill ha och behöver för att vara ditt bästa jag,
              inte bara vad du inte vill ha.
              <br />
              Ta dig tid att fundera på denna frågeställning och anteckna nedan.
              Dina tankar just i denna stund kan vara väldigt viktiga inför
              framtida vägval och vid ett eventuellt medarbetarsamtal med din
              chef framöver.
              <br />
            </label>

            <label>
              Mina sammanfattande anteckningar om{" "}
              <span className={props.section}>{props.title}</span>.
            </label>
            <textarea
              id={"remarks"}
              name={"remarks"}
              {...register("remarks")}
            ></textarea>
          </div>
          <img className="center strengths" src={props.extraImg} />
          <p>{props.extraText}</p>
        </div>

        <div className="buttons-wrapper sticky">
          <Submit
            onClick={onClickSave}
            value="Spara"
            loading={localState.saveButtonSpinner}
          />

          <Submit
            onClick={onClickSaveAndContinue}
            value={`Spara och gå vidare till ${props.nextRoute}`}
            loading={localState.saveAndContinueButtonSpinner}
            className="button button--outline"
          />
        </div>
      </form>
    </div>
  );
};
