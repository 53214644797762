import React from "react";
import "../styling/buttons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const Submit = (props) => {
  return (
    <div key={props.key} className="input-sumbit">
      <button
        key={`submit${props.key}`}
        type="submit"
        className={"button button--primary " + props.className}
        onClick={props.onClick}
        form={props.form}
        disabled={props.disabled}
      >
        {props.value}
        {props.loading && (
          <FontAwesomeIcon
            icon={faSpinner}
            style={{ marginLeft: "0.5rem", height: "15px", width: "15px" }}
            spin
          />
        )}
      </button>
    </div>
  );
};
