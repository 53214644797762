import React from "react";
import { Link, useParams } from "react-router-dom";
import "../styling/login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { TextInput } from "../components/TextInput";
import { Submit } from "../components/Submit";
import axiosInstance from "../REST/axios";
import { useEffect } from "react";

export const ChangePassword = () => {
  const { token } = useParams();

  const [localState, setLocalState] = React.useState({
    password: "",
    confirmPassword: "",
    tokenVerified: false,
    tokenIsValid: false,
    message: "",
    isFormSubmitting: false,
    isError: false,
  });

  const VerifyToken = async () => {
    const response = await axiosInstance.post("/password-recovery/verify", {
      token,
    });

    const data = response.data;
    setLocalState({ ...localState, tokenIsValid: data, tokenVerified: true });
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    setLocalState({ ...localState, isFormSubmitting: true });

    const data = {
      password: localState.password,
      confirmPassword: localState.confirmPassword,
      token: token,
    };

    const response = await axiosInstance.post(
      "/password-recovery/reset-password",
      data
    );

    if (response.data === true) {
      setLocalState({
        ...localState,
        message:
          "Lösenordet har återställts. Var vänlig logga in för att fortsätta",
        isFormSubmitting: false,
        isError: false,
      });
    } else {
      setLocalState({
        ...localState,
        message: "Error resetting password.",
        isFormSubmitting: false,
        isError: true,
      });
    }
  };

  const onChangeInput = (event) => {
    setLocalState({
      ...localState,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    VerifyToken();
  }, []);

  return (
    <div className="section login">
      <Link to="/" className="go-back">
        <FontAwesomeIcon icon={faChevronLeft} />
        Gå tillbaka till inloggning
      </Link>
      <h2>
        Välkommen till OAS och{" "}
        <span style={{ textDecoration: "none", textTransform: "none" }}>
          iTURN.
        </span>
      </h2>
      {localState.tokenVerified === true &&
        (localState.tokenIsValid === true ? (
          <>
            <h1 className="heading" style={{ textTransform: "none" }}>
              Uppdatera lösenord
            </h1>
            <p>Välj ett nytt lösenord.</p>

            <div className="landing-page">
              <form className="landing-page__sign-up">
                <div className="form-wrapper">
                  <TextInput
                    type="password"
                    for="new-password"
                    label="Nytt lösenord"
                    required="required"
                    name="password"
                    onChange={onChangeInput}
                    value={localState.password}
                  />
                  <TextInput
                    type="password"
                    for="repeat-new-password"
                    label="Nytt lösenord"
                    required="required"
                    name="confirmPassword"
                    onChange={onChangeInput}
                    value={localState.confirmPassword}
                  />
                </div>
                <p
                  style={{
                    color: localState.isError === true ? "red" : "green",
                  }}
                >
                  {localState.message}
                </p>
                <Submit
                  value="Ändra lösenordet"
                  loading={localState.isFormSubmitting}
                  onClick={SubmitForm}
                />
              </form>
            </div>
          </>
        ) : (
          <>
            <p>Ogiltig verifieringstoken.</p>
          </>
        ))}
    </div>
  );
};
